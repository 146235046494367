<template>
 <div class="box box-primary">
    <div class="box-header with-border">
        <i class="fa fa-globe"></i>
        <h3 class="box-title text-right">Websites Configuration</h3>
    </div>
    <div class="box-body">
       <div class="box-content box-bg-color">
          <div class="row">
            <div class="col-md-12">
                <div class="col-md-8">
                    <div class="form-group">
                        <div class="row">
                            <label class="col-3 control-label text-left">Website Name:</label>
                            <div class="col-6">
                            <input iservice-id="Name" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 
            <div class="col-md-12">
                <div class="box box-primary">
                    <div class="box-header with-border">
                    <h3 class="box-title text-right">Website Domains and Segments</h3>
                    </div>
                    <div class="box-body">
                    <section class="search-parameters">
                        <div class="row">
                        <div class="col-md-6">
                            <div class="input-group mbottom-15">
                            <input type="text" class="form-control">
                            <span class="input-group-btn">
                                <button type="button" class="btn btn-info btn-flat">Add Domain</button>
                            </span>
                            </div>
                            <div class="form-group">
                            <label class="control-label text-left">Domain Values <span class="data-v-tooltip" data-v-tooltip="The domain specifies the URL that agents use to login this configuration." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                <i class="fa fa-question-circle text-info help-icon"></i>
                                </span>
                            </label>
                            <div class="domain-list">
                                <div class="list-item ng-scope">
                                <button class="icon-button button-delete" type="button">
                                    <i class="fa fa-times-circle"></i>
                                </button>
                                <span>1to1.iservicecrm.com</span>
                                </div>
                               
                            </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                            <label class="control-label text-left"> Segments <span class="data-v-tooltip" data-v-tooltip="Segments selected for the website will have their topics included in public interfaces, like knowledge bases topic lists and customer ticket forms." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                <i class="fa fa-question-circle text-info help-icon"></i>
                                </span>
                            </label>
                            </div>
                            <div class="form-group">
                          
                            <div class="checkbox col-md-6" iservice-id-prefix="'Segment'">
                                <label>
                                <input iservice-id="Selected" type="checkbox" class="form-check-input" value="9"> Brian Adams Sales </label>
                            </div>
                            <div class="checkbox col-md-6" iservice-id-prefix="'Segment'">
                                <label>
                                <input iservice-id="Selected" type="checkbox" class="form-check-input" value="7"> Compliance &amp; Security </label>
                            </div>
                            <div class="checkbox col-md-6" iservice-id-prefix="'Segment'">
                                <label>
                                <input iservice-id="Selected" type="checkbox" class="form-check-input" value="13"> DevOps </label>
                            </div>
                            
                            </div>
                        </div>
                        </div>
                    </section>
                    </div>
                </div>
                </div>

                <div class="col-md-12">
                    <div class="box box-primary">
                        <div class="box-header with-border">
                        <h3 class="box-title text-right">Website URLs</h3>
                        </div>
                        <div class="box-body">
                        <div class="row">
                            <div class="col-md-6">
                            <div class="form-group has-error">
                                <label class="col-12 control-label text-left"> Website Root URL <span class="data-v-tooltip" data-v-tooltip="When variables are inserted into your auto response template or mass mailing, a URL must be generated (e.g., an unsubscribe link). Specify the URL to be used, which is typically one of the domains listed for the website. If using a virtual directory, the root path for your website should be listed rather than just the domain." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                    <i class="fa fa-question-circle text-info help-icon"></i>
                                </span>
                                </label>
                                <div class="col-12">
                                <input iservice-id="RootUrl" type="text" class="form-control">
                                <!--v-if-->
                                </div>
                            </div>
                            </div>
                            <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-12 control-label text-left"> Anti-Spam Policy URL <span class="data-v-tooltip" data-v-tooltip="If you enter a URL in this box, it will display a link to this page on the bottom of each iService page with the text ‘Anti-Spam Policy’. Clicking this link will load the page specified, which should provide a description of your policy for ensuring your iService tenant is not used to send unsolicited commercial email." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                    <i class="fa fa-question-circle text-info help-icon"></i>
                                </span>
                                </label>
                                <div class="col-12">
                                <input iservice-id="SpamUrl" type="text" class="form-control">
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-12">
                    <div class="box box-primary">
                        <div class="box-header with-border">
                        <h3 class="box-title text-right"> Password Reset Settings </h3>
                        </div>
                        <div class="box-body">
                        <div class="row">
                            <div class="col-md-6">
                            <div class="form-group has-error">
                                <label class="col-12 control-label text-left"> Outbound Mailbox <span class="data-v-tooltip" data-v-tooltip="When a user of the iService Website requests their password, it is sent using the mailbox selected from this list. Mailboxes are created and maintained from the Segments > Mailboxes tab. The forgot password link will not appear on the login panel if this mailbox is not set." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                    <i class="fa fa-question-circle text-info help-icon"></i>
                                </span>
                                </label>
                                <div class="col-12">
                                    <Multiselect
                                        v-model="value"
                                        mode="tags"
                                        placeholder="Select or search for mailbox in the list"
                                        label="name"
                                        :groups="true"
                                        :searchable="true"
                                        :options="[
                                            {
                                            label: 'Customer Success',
                                            options:  [
                                                { value: 'iService', name: 'iService',level:'1'},
                                                { value: 'Abuse Support', name: 'Abuse Support',level:'1'},
                                                
                                            ],
                                            }
                                        ]"
                                        >
                                        <template v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-6">
                            <div class="form-group has-error">
                                <label class="col-12 control-label text-left">Password Reset Notification Template <span class="data-v-tooltip" data-v-tooltip="When a user of the iService Website requests their password, it is sent using the notification selected from this list. Password Reset notifications are created in the Segments - Notifications page. Only notifications that are marked as password reset are displayed in this drop down." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                    <i class="fa fa-question-circle text-info help-icon"></i>
                                </span>
                                </label>
                                <div class="col-12">
                                  <Multiselect
                                        v-model="value"
                                        mode="tags"
                                        placeholder="Select or search response template in the list"
                                        label="name"
                                        :groups="true"
                                        :searchable="true"
                                        :options="[
                                            {
                                            label: 'Customer Success',
                                            options:  [
                                                { value: 'iService', name: 'iService',level:'1'},
                                                { value: 'Password Reset (iservice)', name: 'Password Reset (iservice)',level:'1'},
                                                
                                            ],
                                            }
                                        ]"
                                        >
                                        <template v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
          </div>
       </div>
    </div>
</div>
</template>

<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import SkillsPropertyForm from '../components/SkillsPropertyForm.vue';
import SkillsDetail from '../components/SkillsDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Website Detail',
   components: {
    Breadcrumb,
    iServiceSwitch,
    Multiselect,
    SkillsPropertyForm,
    Toggle,
    Multiselect,
    SkillsDetail
  },
  setup(){      
       
      
  }

}
</script>
